import React from 'react';

import { Navbar, Nav, Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

const Navigation = () => {
  return (
    <header>
      <div className="top-bar">
        <Container className="nav-container">
          <Navbar expand="md" variant="light">
            <Container className="sub-nav-container">
              <Navbar.Brand href="https://www.anawimhousing.org/"><Image src="//www.anawimhousing.org/assets/anawim-logo-2-aef55b1ba715d1e2ef8bd25017cf30f3.png" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav" className="collapse navbar-collapse d-lg-flex mb-auto justify-content-lg-end">
                <Nav className="ml-auto">
                  <Nav.Link href="https://www.anawimhousing.org/" className="anawim-link">Home</Nav.Link>
                  <span class="separator">|</span>
                  &nbsp;
                  <Nav.Link href="/map" className="anawim-link">Map</Nav.Link>
                  <span class="separator">|</span>
                  &nbsp;
                  <Nav.Link href="/data" className="anawim-link">Data</Nav.Link>
                  <span class="separator">|</span>
                  &nbsp;
                  <Nav.Link href="https://www.anawimhousing.org/contact-us" className="anawim-link">Contact Us</Nav.Link>
                  <span className="separator">|</span>
                  &nbsp;
                  <Nav.Link href="https://www.anawimhousing.org/faq-page" className="anawim-link">FAQ</Nav.Link>
                  <span className="separator">|</span>
                  &nbsp;
                  <Nav.Link href="https://www.anawimhousing.org/about-us" className="anawim-link">About Us</Nav.Link>
                  <span className="separator">|</span>
                  &nbsp;
                  <Nav.Link href="https://www.anawimhousing.org/newsletter-sign-up" className="anawim-link">Newsletter Sign-Up</Nav.Link>
                  <span className="separator">|</span>
                  &nbsp;
                  <Nav.Link href="https://www.anawimhousing.org/refinery/login" className="anawim-linkend"><i className="sprite-openlock">Board of Directors</i></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </div>
    </header>
  );
}

export default Navigation;