const contentful = require('contentful')

const multipleColumn = require('../ContentfulTransforms/MultipleColumn.js');
const singleColumn = require("../ContentfulTransforms/SingleColumn.js");
const singleStatistic = require("../ContentfulTransforms/SingleStatistic.js");

const Cards = async (config) => {

    const client = contentful.createClient(config.contentfulConfig);

    const results = await client.getEntries({
        "content_type": "dashboardCardArrangement",
        "include": "4"
    });

    const cards = results.items.map((dashboardCardArrangement) => {
        const card = dashboardCardArrangement.fields;

        const cardData = dashboardCardArrangement.fields.cardData;

        if (cardData['fields']) {


            switch (card['cardType']) {
                case "Single Statistic":
                    card['data'] = singleStatistic(cardData);
                    break;
                case "Horizontal Bar Chart":
                    card['data'] = singleColumn(cardData);
                    break;
                case "Pie Chart":
                    card['data'] = singleColumn(cardData);
                    break;
                case "Statistic Table":
                    card['data'] = multipleColumn(cardData);
                    break;
                default:
                    break;
            }

        }
        // we don't set data


        delete card['cardData'];
        return card;
    });

    return cards;
};
export default Cards;