import React from 'react';
import Doughnut from 'react-chartjs-2';
import randomColor from 'randomcolor';
import './Card.css';


class PieChartCard extends React.Component {

    constructor(props) {
        super(props);

        this.pieChartData = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: []
            }]
        };

        if (props.card['data']) {
            props.card.data.rows.forEach((cur) => {
                this.pieChartData.labels.push(cur['title']);
                this.pieChartData.datasets[0].data.push(cur['data'][0]);
                this.pieChartData.datasets[0].backgroundColor.push(randomColor());
                this.pieChartData.datasets[0].hoverBackgroundColor.push(randomColor());
            });
        }

    }

    render() {
        return (

            <div className="card h-100 anawim shadow">
                <h4 className="card-header text-uppercase">{this.props.card.cardTitle}</h4>
                <div className="card-body">
                    <Doughnut data={this.pieChartData} />
                </div>
            </div>
        );
    }
}
export default PieChartCard;