import React from 'react';
import HorizontalBarChartCard from "./HorizontalBarChartCard";
import PieChartCard from "./PieChartCard";
import StatisticsTableCard from "./StatisticsTableCard";
import StatCard from "./StatCard";
import Cards from "../Contentful/Cards";
import Page from "../Contentful/Page";
import Config from "../config.js";


class data extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasCards: false,
      cardGroups: [],
      page: {
        formattedBody: null
      }
    };
  }

  async componentDidMount() {
    const config = new Config();
    const cards = await Cards(config);

    cards.sort((a, b) => {
      if (a.rowIndex > b.rowIndex) return 1;
      if (b.rowIndex > a.rowIndex) return -1;

      return 0;
    });

    const cardGroups = cards.reduce((acc, card) => {
      const rowIndex = card.rowIndex;

      if (!(rowIndex in acc)) {
        acc[rowIndex] = [];
      }
      acc[rowIndex].push(card);
      return acc;
    }, {});

    for (let groupKey in cardGroups) {
      const cardGroup = cardGroups[groupKey];
      cardGroup.sort((a, b) => {
        if (a.columnIndex > b.columnIndex) return 1;
        if (b.columnIndex > a.columnIndex) return -1;

        return 0;
      });
    }

    const page = await Page(config, "data");
    console.log(page);

    this.setState((state) => {
      state.hasCards = (cards.length > 0) ? true : false;
      state.cardGroups = cardGroups;
      if (page) {
        state.page.formattedBody = page.formattedBody;
      }
      return state;
    })
  }

  cardComponent(card) {
    switch (card.cardType) {
      case "Single Statistic":
        return <StatCard card={card} />;
      case "Horizontal Bar Chart":
        return <HorizontalBarChartCard card={card} />;
      case "Pie Chart":
        return <PieChartCard card={card} />;
      case "Statistic Table":
        return <StatisticsTableCard card={card} />;
      default:
        return "";
    }
  }

  render() {
    return (
      <div className="App" >
        <div className="container">
          {this.state.page.formattedBody &&
            <div className="row mt-5">
              <div className="col col-left-align">
                {this.state.page.formattedBody}
              </div>
            </div>
          }
          {this.state.hasCards &&

            Object.keys(this.state.cardGroups).map((groupKey) => {
              const cardGroup = this.state.cardGroups[groupKey];

              return <div key={groupKey} className="row mt-5">
                {cardGroup.map((card, index, list) => {
                  const listLen = (list.length > 0) ? list.length : 1;
                  const len = 12 / listLen;
                  const classText = "col-lg-" + len + " mb-4 grid-margin";
                  const footerText = <div className="card-footer">FOOTER</div>
                  return <div className={classText} key={index}>{this.cardComponent(card)}</div>
                })}
              </div>
            })}
        </div>
      </div>
    );
  }
}

export default data;