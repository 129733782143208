class Config {
    constructor() {
        this.contentfulConfig = {
            space: "0vn8jg4wbhzb",
            accessToken: "wfd5gGUy06cmZTMdofof1Y3rKT4Hez5vs1YKvQg0Ows"
        };
        this.contentTypes = {
            analytics: {
                contents: [
                    {
                        contentTypeId: 'milestone',
                        fields: [],
                        data: {}
                    },
                    {
                        contentTypeId: 'milestoneName',
                        fields: [],
                        data: {}
                    },
                    {
                        contentTypeId: 'milestoneComparison',
                        fields: [],
                        data: {}
                    },
                ]
            },
            facilities: {
                contents: [
                    {
                        contentTypeId: 'facility',
                        fields: [],
                        data: {}
                    },
                    {
                        contentTypeId: 'facilityDataOrganization',
                        fields: [],
                        data: {}
                    }
                ]
            },
            maps: {
                contents: []
            }
        };
        this.facilityDataKey = 'fieldCategory';
        this.facilityDataValue = 'fieldId';
    }
}

//NOTE: The contentTypes may actually have id/name mappings.
export default Config
