import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
// consts: [34.0522, -118.2437]
import DES_MOINES from '../const/des_moines';
import facilitiesService from '../clients/facilities.client'
import isEmpty from 'lodash.isempty';
import ReactDOMServer from 'react-dom/server';
import Page from "../Contentful/Page";
import Config from "../config.js";
import ReactMarkdown from 'react-markdown/with-html';


const getInfoWindowString = facility => {
    let imageDiv;

    if (isEmpty(facility.photos)) {
      imageDiv = <img className="img-location" src="" alt={`${facility.projectName}`}/>
    }
    else {
      imageDiv = facility.photos.map((photo, index) => 
      <li class="image-list-li" key={index}>
          <a target='_blank' href={photo.url}>
                <img
                className="img-location"
                src={photo.url}
                alt={`${facility.projectName}`}
                />
            </a>
        </li>
      );
    }

    const locationFields = Object.keys(facility).map(function(key) {
        return facility.hasOwnProperty(key) && key !== 'addressLine2' && key !== 'id' && key !== 'projectName' && key !== 'projectCompleted' && key !== 'totalUnits' && key !== 'affordability' && key !== 'otherNotes' && key !== 'addressLine1' && key !== 'city' && key !== 'state' && key !== 'zip' && key !== 'photos' && key !== 'latlong' && key !== 'show'
            ? <div class="loc-field">
                <span class="loc-label">{`${key}`}:</span> {facility[key]}
            </div> : <div />
    });

    return ReactDOMServer.renderToStaticMarkup(<div>
        <ul class="image-list-ul">
            {imageDiv}
        </ul>
        <div className="loc-name">
            {facility.projectName}
        </div>
        <div class="loc-field-head">
            {`${facility.addressLine1} ${facility.addressLine2 ? facility.addressLine2 : ""} ${facility.city}, ${facility.state} ${facility.zip}`}
        </div>
        <div class="loc-field">
            <span class="loc-label">Project Completed:</span> {facility.projectCompleted}
        </div>
        <div class="loc-field">
            <span class="loc-label">Total Units:</span> {` ${facility.totalUnits}`}
        </div>
        <div class="loc-field">
            <span class="loc-label">Affordability:</span> {` ${facility.affordability}`}
        </div>
        <div class="loc-field">
            <span class="loc-label">Other Notes:</span> 
                <ReactMarkdown
                    source={facility.otherNotes}
                    escapeHtml={false}
                />
        </div>
        {locationFields}
    </div>);
};

// Refer to https://github.com/google-map-react/google-map-react#use-google-maps-api
const handleApiLoaded = (map, maps, facilities) => {
    const markers = [],
        infowindows = [],
        bounds = new window.google.maps.LatLngBounds();
    var windowToClose;

    facilities.forEach((facility) => {
        const marker = new maps.Marker({
            position: {
                lat: facility.latlong.lat,
                lng: facility.latlong.lon,
            },
            map,
        });
        markers.push(marker);
        bounds.extend(marker.position);

        infowindows.push(new maps.InfoWindow({
            content: getInfoWindowString(facility)
        }));
    });

    markers.forEach((marker, i) => {
        marker.addListener('click', () => {
            if (windowToClose !== null) {
                if (windowToClose !== undefined) {
                    infowindows[windowToClose].close();

                }
            }
            infowindows[i].open(map, marker);
            windowToClose = i;
        });
    });

    /* Commenting out for now.  Feedback was to keep zoomed into Des Moines as that's where the marjority are */
    //map.fitBounds(bounds);
};

class Map extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          page: {
            formattedBody: null
          }
        };
      }

    async componentDidMount() {
        const config = new Config();
        const page = await Page(config, "map");
        console.log(page);
    
        this.setState((state) => {
          if (page) {
            state.page.formattedBody = page.formattedBody;
          }
          return state;
        })
      }
      
    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{height: '72.5vh', width: '100%'}} className="map-ui">
                <ul className="map-ul">
                    <li className="map-li"><a href="/map/z/12">DES MOINES</a></li>
                    <li className="map-li"><a href="/map/z/8">IOWA</a></li>
                </ul>
                {this.state.page.formattedBody &&
                    <div className="row mt-2">
                    <div className="col">
                        {this.state.page.formattedBody}
                    </div>
                    </div>
                }
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyA6S6b_ObKpB03Unis3x-l_yV-khM_7Z6s"}}
                    defaultCenter={DES_MOINES}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={this.props.match.params.zoom ? parseInt(this.props.match.params.zoom, 10) : 12}  // 7 for iowa, 12 for des moines
                    onGoogleApiLoaded={({map, maps}) => {
                        const facilities = new facilitiesService().getFacilities();
                        facilities.then(data => {
                            data.forEach((result) => {
                                result.show = false; // eslint-disable-line no-param-reassign
                            });
                            handleApiLoaded(map, maps, data);
                        });
                    }}
                >
                </GoogleMapReact>
            </div>
        );
    }
}

export default Map;