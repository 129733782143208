import * as Contentful from 'contentful';
import Config from '../../config';

class CoreBaseClientContentTypeField {
    constructor(id, name, disabled) {
        this.id = id;
        this.name = name;
        this.disabled = disabled;
    }
}

class CoreBaseClientContentType {
    constructor(id, name, description, displayField, fields) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.displayField = displayField;
        this.fields = fields;
    }
}

class ContentfulBaseClient {
    constructor() {
        this.config = new Config();
        this.contentfulClient = Contentful.createClient(this.config.contentfulConfig);
    }

    // Assign additional members and do other initialization things.
    async init(initializationId) {
        this.isMultiFacetedContentType = initializationId === 'analytics';
        this.initializationId = initializationId;
        const initializingContentTypes = await this.getMappedContentTypes(initializationId);
        this.contentTypes = initializingContentTypes;
    }

    getMappedClientContentType(clientContentType) {
        const {
            name,
            description,
            displayField,
            fields
        } = clientContentType;

        return new CoreBaseClientContentType(this.initializationId, 
            name, 
            description, 
            displayField, 
            fields.map(field => {
                return new CoreBaseClientContentTypeField(
                    field.id,
                    field.name,
                    field.disabled
                );}
            )
        );
    }

    async getMappedContentTypes() {
        try {
            if (this.isMultiFacetedContentType) {
                return await this.getMappedMultiFacetedContentType();
            }
            const clientContentType = await this.contentfulClient.getContentType(this.initializationId);
            return [this.getMappedClientContentType(clientContentType)];
        }
        catch(ex) {
            console.log(ex);
            throw ex;
        }
    }

    async getMappedMultiFacetedContentType() {
        const multiFacetedContentTypeList = '|combinedDemographics|graphContainer|milestoneName|milestone|milestoneComparison|facilityDataOrganization|';
        const clientContentTypes = await this.contentfulClient.getContentTypes()
            .items
            .filter(item => multiFacetedContentTypeList.indexOf(`|${item.name}|`) > -1)
            .map(contentType => {
                return this.getMappedClientContentType(contentType);
            });
        return clientContentTypes;
    }
}

export default ContentfulBaseClient;