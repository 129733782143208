import React from 'react';
import { Table } from 'react-bootstrap';
import './Card.css';

class StatisticsTableCard extends React.Component {
    render() {
        return (
            <div className="card h-100 anawim shadow">
                <h4 className="card-header text-uppercase">{this.props.card.cardTitle}</h4>
                <div className="card-body">
                    {this.props.card['data'] ? (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    {this.props.card.data.headers.map((value, index) => {
                                        return <th key={index}>{value}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.card.data.rows.map((row, index1) => {
                                    return <tr key={index1}>
                                        <td>{row.title}</td>
                                        {row.data.map((value, index2) => {
                                            return <td key={index2}>{value}</td>
                                        })}
                                    </tr>
                                })}
                            </tbody>
                        </Table>) : (<span>No Data</span>)}
                </div>
            </div>
        );
    }
}
export default StatisticsTableCard;