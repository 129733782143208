
import ContentfulBaseClient from './shared/core.client';


class FacilitiesClient extends ContentfulBaseClient {
    constructor() {
        super();
        this.initializer = 'facility';
    }

    async getFacilities() {
        try {
            await this.init(this.initializer);
            const clientFacilities = await this.contentfulClient.getEntries({'content_type': `${this.contentTypes[0].id}`});
            if (clientFacilities.items.length === 0) {
                return [];
            }

            return this.mapFacilities(clientFacilities.items);
        }
        catch(ex) {
            console.log(ex);
            throw ex;
        }
    }

    async getFacility(facilityId) {
        try {
            await this.init(this.initializer);
            const clientFacility = await this.contentfulClient.getEntry(facilityId, {'content_type': `${this.contentTypes[0].id}`});
            return this.mapFacility(clientFacility);
        }
        catch(ex) {
            console.log(ex);
            throw(ex);
        }
    }

    mapFacilities(facilities) {
        if (!facilities || facilities.length === 0) {
            return [];
        }

        return facilities.map(facility => {
            return this.mapFacility(facility);
        });
        
    };

    mapFacility(clientFacility) {
        const fields = this.prepareFacilityForPresentation(clientFacility.fields, clientFacility.sys.id);
        return fields;
    }

    prepareFacilityForPresentation(fields, facilityId) {
        let retVal = fields;
        Object.assign(retVal, {id: facilityId});
        if (fields.photos) {
            Object.assign(retVal, { photos: retVal.photos
                .map(p=>{
                    return { 
                        url: p.fields.file.url,
                        width: p.fields.file.details.image.width,
                        height: p.fields.file.details.image.height
                    };
                })});
        }
        return retVal;
    };
}

export default FacilitiesClient;