import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Map from './components/Map';
import Data from './components/Data';
import Error from './components/Error';
import Navigation from './components/Navigation';
 
/*
  // HACK: These comments were previously in index.html, keeping for posterity.

    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <!--
      manifest.json provides metadata used when your web app is installed on a
      user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
    -->

    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <!--
      Notice the use of %PUBLIC_URL% in the tags above.
      It will be replaced with the URL of the `public` folder during the build.
      Only files inside the `public` folder can be referenced from the HTML.

      Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
      work correctly both with client-side routing and a non-root public URL.
      Learn how to configure a non-root public URL by running `npm run build`.
    -->
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
*/
//import 'bootstrap/dist/css/bootstrap.min.css';
class App extends Component {
  render() {
    return ( 
      <div>
       <MetaTags>
        <title>Anawim Community</title>
        <meta name="google-site-verification" content="eGE1gjJAlMjcpmh2mIngPiSv8KmdEZ_y6UJchvFLIrE" />
        <meta charSet="utf-8" />
        <meta property="og:title" content="Anawim Community" />
        <link rel="stylesheet" href="./index.css" />
        <link rel="stylesheet" href="./App.css" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Anawim Housing is an industry leader in the development of affordable housing and leveraging LIHTC funding available through Iowa Finance Authority. Anawim experts are available for consultation and collaboration in providing innovative solutions to the shortage of affordable housing throughout Iowa. This map highlights successful Anawim projects in 20 years of development." />
        <meta name="keywords" content="Anawim Housing affordable housing sustainable housing Iowa Des Moines IA LIHTC Iowa Finance" />
       </MetaTags>
       <BrowserRouter>
          <Navigation />
            <Switch>
             <Route path="/" component={Map} exact/>
             <Route exact path="/map" component={Map}/>
             <Route exact path="/map/z/:zoom" component={Map}/>
             <Route path="/data" component={Data}/>
            <Route component={Error}/>
           </Switch>
      </BrowserRouter>
      </div>
    );
  }
}
 
export default App;