import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Page = async (config, pageId) => {

    const client = contentful.createClient(config.contentfulConfig);

    const results = await client.getEntries({
        "content_type": "page",
        'fields.pageIdentifier': pageId
    });

    let page = null;
    if (results.items.length === 1) {
        page = results.items[0].fields;
        console.log(page);
        const rawBody = page.bodyHeader;
        console.log(rawBody);
        //page['formattedBody'] = documentToHtmlString(rawBody);
        page['formattedBody'] = documentToReactComponents(rawBody)
        console.log(page);
    }

    return page;

};
export default Page;