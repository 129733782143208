/*
Pass in cardData that uses the singleStatistic content-type and generate the data object for the card
Returns a data structure like this:
{
    title: "",
    value: "",
    info:""
}
*/
const singleStatistic = (cardData) => {
    const record = cardData.fields;
    return record;
};
module.exports = singleStatistic;