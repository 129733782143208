/*
Pass in a set of cardData and parse out the multiple columns and render it into a matrix with the supported data format.

Returns a data structure like this:
{
    headers: [
        "HOP Consolidated",
        "Anawim Consolidated",
    ],
    rows: [
        {
            title: "Blah",
            data: [1,2]
        },
        {
            title: "Blarg",
            data: [3,4]
        },

    ]
}
*/

const multipleColumn = (cardData) => {
    const data = {};
    console.log(cardData);
    const columnSets = cardData.fields.columnSets;

    data['headers'] = columnSets.map((columnSet) => {
        return columnSet.fields.programCompared;
    });

    const arrayOfColumns = columnSets.map((columnSet) => {
        const rows = columnSet.fields.milestoneValue.map((row) => {
            const value = row.fields.value;
            const rowName = row.fields.dataPoint.fields.name;
            return { name: rowName, value: value };
        });
        return rows;
    });

    const allRowNames = arrayOfColumns.reduce((acc, cur) => {
        const names = cur.map((cur) => {
            return cur['name'];
        });
        acc = acc.concat(names);
        return acc;
    }, [])

    const uniqueRowNames = allRowNames.reduce((acc, cur) => {
        if (acc.includes(cur) == false) acc.push(cur);
        return acc;
    }, []);

    const rows = uniqueRowNames.map((rowName) => {

        const data = arrayOfColumns.map((column) => {
            const value = column.reduce((acc, cur) => {
                if (rowName == cur['name']) acc = cur['value'];
                return acc;
            }, null);
            return value;
        });

        return {
            title: rowName,
            data: data
        }
    });
    data['rows'] = rows;
    return data;
};
module.exports = multipleColumn;