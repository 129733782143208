import React from 'react';
import './Card.css';
import { Button, Tooltip, Popover, OverlayTrigger } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';

class StatCard extends React.Component {
    render() {
        const popover = (
            <Popover id="popover-basic">
              <Popover.Title as="h3">{this.props.card.cardTitle}</Popover.Title>
              <Popover.Content>
                <ReactMarkdown
                    source={this.props.card.cardNotes}
                    escapeHtml={false}
                />
              </Popover.Content>
            </Popover>
          );
          
        return (
            <div className="card h-100 shadow anawim">
                <h4 className="card-header text-uppercase">{this.props.card.cardTitle}</h4>
                <div className="card-body">
                    <div className="row">
                        <div className="col text-center statistic">
                            {(this.props.card['data']) ? this.props.card.data.value : "No Data"}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            {(this.props.card['data']) ? this.props.card.data.info : "No Data"}
                        </div>
                    </div>
                </div>
                {this.props.card.cardNotes && <div className="card-footer">
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                        <Button variant="success">See Info</Button>
                    </OverlayTrigger>
                  </div>}
            </div>
        );
    }
}
export default StatCard;