/*
Pass in cardData that uses the demographic single column content-type and generate out a data object
Returns a data structure like this:
{
    title: "",
    headers: [
        "Percentage"
    ],
    rows: [
        {
            title: "Blah",
            data: [1]
        },
        {
            title: "Blarg",
            data: [3]
        },
    ]
}
*/

const singleColumn = (cardData) => {
    const data = {};
    const record = cardData.fields;
    data['title'] = record['description'];
    data['headers'] = [record['columnHeader']];
    const valueRows = record['demographicsValues'];

    const rows = valueRows.map((cur) => {
        const value = cur.fields.value;
        const name = cur.fields.dataPoint.fields.name;
        return {
            title: name,
            data: [value]
        };
    });
    data['rows'] = rows;
    return data;
};
module.exports = singleColumn;