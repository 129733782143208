import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import './Card.css';
import { Button, Tooltip, Popover, OverlayTrigger } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown/with-html';

class HorizontalBarChartCard extends React.Component {

    constructor(props) {
        super(props);

        const headers = (props.card['data']) ? props.card.data.headers : [];

        this.barChartData = {
            labels: headers,
            datasets: []
        };

        if (props.card['data']) {

            this.barChartData.datasets = props.card.data.rows.map((cur, index) => {

                let i = index + 1;
                let barColorOne = 'rgba(245, 196, 0)';
                let barColorTwo = 'rgba(245, 223, 135)';
                let barColorThree = 'rgba(245, 236, 201)';
                let barColorFour = 'rgba(189, 189, 189)';
                let barColorFive = 'rgb(110, 110, 110)';
                let bkrColor;


                if (i == 1) { bkrColor = barColorOne };
                if (i == 2) { bkrColor = barColorTwo };
                if (i == 3) { bkrColor = barColorThree };
                if (i == 4) { bkrColor = barColorFour };
                if (i == 5) { bkrColor = barColorFive };

                if (i > 5) {
                    if (Math.floor(i / 5) === 1) { bkrColor = barColorOne };
                    if (Math.floor(i / 5) === 2) { bkrColor = barColorTwo };
                    if (Math.floor(i / 5) === 3) { bkrColor = barColorThree };
                    if (Math.floor(i / 5) === 4) { bkrColor = barColorFour };
                    if (Math.floor(i / 5) === 5) { bkrColor = barColorFive };
                }

                const obj = {
                    label: cur['title'],
                    backgroundColor: bkrColor,
                    borderColor: 'rgba(85,86,90,1)',
                    borderWidth: 1,
                    data: cur['data']
                };

                return obj;
            });

        }
    }

    render() {
        console.log(this.props.card);
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title as="h3">{this.props.card.cardTitle}</Popover.Title>
                <Popover.Content>
                    <ReactMarkdown
                        source={this.props.card.cardNotes}
                        escapeHtml={false}
                    />
                </Popover.Content>
            </Popover>
        );

        return (
            <div className="card h-100 anawim shadow">
                <h4 className="card-header text-uppercase"> {this.props.card.cardTitle} </h4>
                <div className="card-body" >
                    <HorizontalBar data={this.barChartData} />
                </div>
                {this.props.card.cardNotes && <div className="card-footer">
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                        <Button variant="success">See Info</Button>
                    </OverlayTrigger>
                </div>}
            </div>
        );
    }
}
export default HorizontalBarChartCard;